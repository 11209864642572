import { defineComponent, h } from 'vue'
import HeaderMain from '@/components/business/header/HeaderMain.vue'
import HeaderClear from '@/components/business/header/HeaderClear.vue'
import HeaderProfile from '@/components/business/header/HeaderProfile.vue'
import HeaderProduct from '@/components/business/header/HeaderProduct.vue'

export default defineComponent({
  name: 'HeaderWrapper',
  setup () {
    const route = useRoute()

    return () => {
      switch (route.meta.header) {
        case 'clear':
          return h(HeaderClear)
        case 'profile':
          return h(HeaderProfile)
        case 'product':
          return h(HeaderProduct)
        default:
          return h(HeaderMain)
      }
    }
  }
})
