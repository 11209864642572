<template>
  <MPopover
    :open="showCityAlert"
    class-name="header-bar__geo-popover"
    position="bottom-start"
    ignore-close-on-desktop
    with-close
    :z-index="999"
    @close="closeCityPopover"
  >
    <template #content>
      <AImage class="logo" from-public src="/assets/images/logo.svg" width="200" height="36" />

      <div class="description">
        {{ $t("molecules.header.your-city-desc") }}
      </div>
      <div class="buttons">
        <AButton
          v-if="cityToRedirect"
          size="xs"
          @click="changeCity(cityToRedirect)"
        >
          {{ cityToRedirect.name }}
        </AButton>
        <AButton
          :variant="cityToRedirect ? 'secondary' : undefined"
          size="xs"
          @click="confirmCurrentCity"
        >
          {{ currentRegionName }}
        </AButton>
        <AActionButton
          size="sm"
          underline
          @click="openCityModal"
        >
          {{ $t('molecules.header.your-city-change') }}
        </AActionButton>
      </div>
    </template>
  </MPopover>
</template>

<script lang="ts" setup>
import { defineComponent, computed } from 'vue'
import { useRedirect, useHydrationData, useMainDomainCookie, useHydrationRef, isNumber, useLocalization } from '#imports'
import { useConfig } from '@/stores/config'
import { useMainStore } from '@/stores'

import type { LocationCity } from '@/types/locale.d'

import AActionButton from '@/components/atoms/ActionButton/AActionButton.vue'
import AButton from '@/components/atoms/Button/AButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'
import MPopover from '@/components/molecules/Popover/MPopover.vue'

defineComponent({ name: 'HeaderRedirectPopover' })

const allRegions = useMainDomainCookie('all-regions')
const { getRegionsByCountryCode, currentRegion, currentRegionName, mainRegionId } = useConfig()
const { addToRedirectCookie, changeCity } = useRedirect()
const { openModalCity } = useMainStore()
const { t } = useLocalization()

const cityAlertRegionId = useHydrationRef('city-alert-region-id')
const showCityAlert = useHydrationRef('city-alert', false)

const { data: cities } = await useHydrationData('cities-popover', async () => {
  const cities = await getRegionsByCountryCode(currentRegion.value.country?.code ?? '')

  return cities.map(({ id, name, subdomain }) => ({ id, name, subdomain }))
})

const hasCities = computed(() => {
  return cities.value.length > 1
})
const cityToRedirect = computed(() => {
  if (isNumber(cityAlertRegionId.value) && cityAlertRegionId.value !== currentRegion.value.id) {
    const cityData = (cities.value || []).find(({ id }) => id === cityAlertRegionId.value)

    if (!cityData) {
      return undefined
    }

    const withAllRegions = allRegions.value && cityData.id === mainRegionId.value.ru

    let name = cityData.name || ''
    if (name && withAllRegions) {
      name += ' ' + t('other.and-regions')
    }

    return {
      id: cityAlertRegionId.value,
      name: name,
      subdomain: cityData.subdomain || '',
      allRegions: withAllRegions
    } as LocationCity
  }

  return undefined
})

const confirmCurrentCity = (): void => {
  addToRedirectCookie(currentRegion.value.id)
  closeCityPopover()
}

const closeCityPopover = (): void => {
  showCityAlert.value = false
}

const openCityModal = (): void => {
  if (hasCities.value) {
    closeCityPopover()
    openModalCity()
  }
}
</script>
