import { useConfig } from '@/stores/config'

export function useNotification () {
  const { getRegionAnnouncements } = useConfig()

  async function getNotifications () {
    try {
      const regionAnnouncements = await getRegionAnnouncements()

      if (!Array.isArray(regionAnnouncements)) { return [] }

      return regionAnnouncements
    } catch (err) {
      return []
    }
  }

  return {
    getNotifications
  }
}
