<template>
  <HeaderBase>
    <template #middle>
      <div class="header-clear__logo">
        <a href="/">
          <AImage
            from-public
            src="/assets/images/logo.svg"
            width="110"
            height="20"
          />
        </a>
        <ServerRender
          is="Badge"
        >
          {{ $t("other.id") }}
        </ServerRender>
      </div>
    </template>

    <template #right>
      <AIconButton
        icon-name="close-grey"
        @click="backToPrevOrMain"
      />
    </template>
  </HeaderBase>
</template>

<script setup lang="ts">
import ServerRender from '@/components/helpers/ServerRender'

import { useRouter } from 'vue-router'
import { useNavigate } from '@/composables/useNavigate'

import HeaderBase from './HeaderBase.vue'
import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'
import AImage from '@/components/atoms/Image/AImage.vue'

const { navigatePush } = useNavigate()
const router = useRouter()

const backToPrevOrMain = async (): Promise<void> => {
  if (router.options.history.state.back === null) {
    await navigatePush('')
  } else {
    router.back()
  }
}
</script>

<style lang="postcss">
.header-clear__logo {
  display: none;
  margin: 0 auto;
  padding-left: var(--spacer-3xl);

  & .image {
    display: flex;

    & img {
      height: 1.25rem;
    }
  }

  & > .badge {
    --badge-background-color: var(--primary);
    --badge-border-radius: var(--border-radius-xs);

    margin-left: var(--spacer-3xs);
    padding: 0 var(--spacer-4xs);

    @mixin text-lg;
  }

  @media (--screen-xs) {
    display: flex;
    align-items: center;

    & + .header__input {
      display: none;
    }
  }
}
</style>
