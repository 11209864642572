<template>
  <div ref="header" class="header" :class="{ hidden: activeModal?.[0] === 'search' }">
    <div class="container">
      <div class="mobile-only">
        <slot name="left" />
      </div>

      <div class="desktop-only">
        <div class="header__left">
          <a class="header__logo" href="/">
            <AImage from-public src="/assets/images/logo.svg" width="200" height="36" />
          </a>

          <AButton class="catalog-btn" :class="{ open: showHeaderMegamenu }" @click="toggleMegamenu">
            {{ $t("molecules.header.catalog") }}
          </AButton>
        </div>
      </div>

      <slot name="middle" />

      <div
        ref="headerSearchElem"
        class="header__input"
      >
        <ATextfields
          v-model="searchValue"
          clearable
          type="search"
          :placeholder="$t('molecules.search-bar.placeholder')"
          @focus="openSearch"
          @click.stop="openSearch"
        />

        <div v-if="!searchValue" @click.stop="openSearch">
          {{ $t("molecules.search-bar.placeholder-short") }}
          <AImage
            src="/assets/images/logo-gray.svg"
            width="80"
            height="16"
            from-public
          />
        </div>

        <AIconButton
          icon-name="search-white"
          variant="primary"
          @click.stop="openSearch"
        />

        <LazyHeaderSearch
          v-if="isSearchOpen"
          auto-focus
          :search-value="searchValue"
          @close="isSearchOpen = false"
          @set:search-value="e => searchValue = e"
        />
      </div>

      <div class="desktop-only">
        <div class="header__nav" @click="showHeaderMegamenu ? toggleMegamenu() : null">
          <HeaderNavProfile />
          <HeaderNavFavorite />
          <NavItem
            :to="getUrl('cart.php', false)"
            :label="$t('molecules.header.cart')"
            :badge="totalProductsAmount"
            icon="i-cart-header"
            no-prefetch
          />
        </div>
      </div>

      <div class="mobile-only">
        <slot name="right" />
      </div>
    </div>

    <LazyHeaderMegamenu
      v-if="showHeaderMegamenu"
      :is-open="openHeaderMegamenu"
      @close="toggleMegamenu"
    />
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent, ref, onBeforeUnmount } from 'vue'
import { useSearchStore } from '@/stores/search'
import { useRouter, useRoute } from 'vue-router'
import { onClickOutside } from '@vueuse/core'
import { useNavigate } from '@/composables/useNavigate'
import { useCartStore } from '@/stores/cart'
import { useMediaQuery } from '@/composables/media-query'

import AIconButton from '@/components/atoms/IconButton/AIconButton.vue'
import ATextfields from '@/components/atoms/Textfields/ATextfields.vue'
import AImage from '@/components/atoms/Image/AImage.vue'
import AButton from '@/components/atoms/Button/AButton.vue'

import NavItem from './HeaderNavItem.vue'
import HeaderNavProfile from './HeaderNavProfile.vue'
import HeaderNavFavorite from './HeaderNavFavorite.vue'

const LazyHeaderSearch = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/business/header/HeaderSearch.vue')
)
const LazyHeaderMegamenu = defineAsyncComponent(
  () => /* @vite-ignore */ import('@/components/business/header/HeaderMegamenu.vue')
)

defineEmits(['toggle:megamenu'])

const router = useRouter()
const route = useRoute()
const { getUrl } = useNavigate()
const { openModal, activeModal } = useModals()
const { searchValue, showHeaderMegamenu, openHeaderMegamenu, toggleMegamenu } = useSearchStore()
const { totalProductsAmount } = useCartStore()

const header = ref<HTMLDivElement>()
const headerSearchElem = ref<HTMLDivElement>()
const isSearchOpen = ref(false)

const openSearch = () => {
  if (window.innerWidth < 1024 && !activeModal.value) {
    openModal('search', { autoFocus: true })
  } else {
    isSearchOpen.value = true
    showHeaderMegamenu.value = false
  }
}

router.afterEach((to) => {
  if (to.query.search_query) { return }
  searchValue.value = ''
})

let stickyObserver: IntersectionObserver | undefined

useMediaQuery(false, () => {
  isSearchOpen.value = false
})

onMounted(() => {
  if (route.query.search_query) {
    searchValue.value = route.query.search_query as string
  }

  onClickOutside(headerSearchElem, () => {
    isSearchOpen.value = false
  })

  if (header.value) {
    stickyObserver = new IntersectionObserver(
      ([e]) => {
        e.target.classList.toggle('with-border', e.intersectionRatio < 1)
      },
      { threshold: [1] }
    )

    stickyObserver.observe(header.value)
  }
})

onBeforeUnmount(() => {
  if (header.value) {
    stickyObserver?.unobserve?.(header.value)
  }
})
</script>

<style lang="postcss">
.header {
  position: sticky;
  top: -1px;
  z-index: 110;
  padding: var(--spacer-3xs);
  background: var(--color-white);
  box-shadow: var(--shadow-2xs);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--z-sticky);
    height: .5rem;
    background: #fff;
  }

  &.with-border {
    border-bottom: 1px solid var(--color-neutral-300);
  }

  & > .container {
    position: relative;
    z-index: var(--z-modal);
    display: flex;
    align-items: center;
    padding: 0;
  }

  .desktop-only {
    display: none;
    flex-shrink: 0;

    @media (--screen-lg) {
      display: flex;
    }
  }

  .mobile-only {
    display: flex;

    @media (--screen-lg) {
      display: none;
    }
  }

  .icon-button {
    height: fit-content;
  }

  .catalog-btn {
    position: relative;
    display: none;
    gap: 0;
    margin: 0 var(--spacer-xs) 0 var(--spacer-base);
    padding: 0 var(--spacer-3xs);
    font-size: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: var(--spacer-sm);
      width: 1rem;
      height: 3px;
      border-radius: var(--border-radius-2xs);
      background-color: var(--color-white);
      transition: all 0.1s linear;
      transform: translateX(-50%);

      @media (--screen-base) {
        left: var(--spacer-base);
      }
    }

    &::before {
      top: 15px;
    }

    &::after {
      bottom: 15px;
    }

    &.open::before {
      width: 1.25rem;
      transform: translate(-50%, 3.5px) rotate(-45deg);
    }

    &.open::after {
      width: 1.25rem;
      transform: translate(-50%, -3.5px) rotate(45deg);
    }

    @media (--screen-lg) {
      display: block;
      min-width: 2.5rem;
    }

    @media (--screen-base) {
      min-width: 120px;
      padding: 0 var(--spacer-2xs) 0 var(--spacer-3xl);
      font-size: var(--fs-base);
    }
  }

  &__nav {
    display: flex;
    gap: var(--spacer-base);
    align-items: center;
    margin-left: var(--spacer-3xl);
  }

  &__input {
    position: relative;
    width: calc(100% - 120px);

    & > .textfield {
      --textfields-height: 2.5rem;

      padding: 0 var(--spacer-4xs);

      & + div {
        position: absolute;
        top: var(--spacer-3xs);
        left: var(--spacer-sm);
        display: flex;
        gap: var(--spacer-4xs);
        background-color: var(--color-white);
        color: var(--color-text-middle);

        & > .image {
          height: fit-content;
          margin-top: 0.375rem;
        }

        @mixin text-base-bold;
      }
    }

    & > .icon-button {
      position: absolute;
      top: var(--spacer-3xs);
      right: var(--spacer-3xs);
      display: none;
    }

    @media (--screen-lg) {
      padding: var(--spacer-3xs);

      & > .textfield {
        padding: 0;

        & + div {
          display: none;
        }
      }

      & > .icon-button {
        display: block;
      }

      & > .header-search {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
      }
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__logo {
    height: 100%;

    .image {
      display: block;
      width: 12.5rem;
      height: 2.25rem;
      margin-top: var(--spacer-5xs);
    }
  }

  @media (--screen-lg) {
    padding: var(--spacer-xs) 0 var(--spacer-2xs);
    box-shadow: none;

    & > .container {
      padding: 0 var(--spacer-lg);
    }
  }

  @media (--screen-xs) {
    &.hidden {
      z-index: 1;
    }
  }
}
</style>
