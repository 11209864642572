import type { IconName } from '@/utils/icon-types'

export type TabBarItem = {
  count?: number
  key: string
  iconName: IconName
  addSlash: boolean
  href?: string
}

export const getTabBarData = (): TabBarItem[] => {
  const items = [
    {
      iconName: 'house-grey' as IconName,
      href: '',
      addSlash: true,
      key: 'home'
    },
    {
      iconName: 'catalog-grey' as IconName,
      title: 'Каталог',
      addSlash: true,
      key: 'catalog'
    },
    {
      iconName: 'cart-grey' as IconName,
      href: '/cart.php',
      addSlash: false,
      key: 'cart'
    },
    {
      iconName: 'favorite-tabbar' as IconName,
      href: '/wishlist.php',
      addSlash: false,
      key: 'favorites'
    },
    {
      iconName: 'user-grey' as IconName,
      href: 'private_office',
      addSlash: true,
      key: 'profile'
    }
  ]

  return items
}
