<template>
  <component
    :is="markRaw(activeSidebar[1])"
    v-if="activeSidebar"
    v-bind="activeSidebar[2]"
  />
</template>

<script lang="ts" setup>
import { markRaw } from 'vue'
import { useModals } from '../composables'

const { activeSidebar } = useModals()
</script>
