<template>
  <NavItem
    :to="getUrl('wishlist.php', false)"
    :label="$t('molecules.header.favorite')"
    :badge="favoriteProducts.length"
    icon="i-favorite-header"
    no-prefetch
  />
</template>

<script setup lang="ts">
import { useFavoritesStore } from '@/stores/favorites'
import { useNavigate } from '@/composables/useNavigate'
import NavItem from './HeaderNavItem.vue'

const { productIds: favoriteProducts } = useFavoritesStore()
const { getUrl } = useNavigate()
</script>
